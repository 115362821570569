import React from 'react'
import { Link } from 'react-router-dom'

import ArrowRight from '@components/Icons/ArrowRight'

import styles from './styles.module.css'

const DonorCard = ({ donor }) => {
  return (
    <li className={styles['donor-card']}>
      <Link to={`/${donor.donorId}`} target='_blank' rel='noopener noreferrer'>
        <div>
          <em>{donor.name}</em>
          <span>{donor.FCP}</span>
        </div>
        <ArrowRight />
      </Link>
    </li>
  )
}

export default DonorCard
