import React, { useState } from 'react'

import { useLanguageContext } from '@cvg/react-hooks'
import { Input, Loader } from '@cvg/react-components'

import { SUPPORTER_CODE_LENGTH } from '@utils/constants'

import styles from './styles.module.css'

const DonorLoginForm = ({ error, isLoading, onSubmit }) => {
  const { getTranslation } = useLanguageContext()
  const [code, setCode] = useState(Array(SUPPORTER_CODE_LENGTH).fill(''))

  const isButtonDisabled = code.join('').length < SUPPORTER_CODE_LENGTH || isLoading

  const handleSubmit = async event => {
    event.preventDefault()
    await onSubmit(code.join(''))
  }

  return (
    <div className={styles['login-form']}>
      <p>
        {getTranslation('Enter your unique ___ character code to access your supporter dashboard.', SUPPORTER_CODE_LENGTH)}
      </p>

      <form onSubmit={handleSubmit}>
        <Input
          aria-label='Enter unique pincode'
          inputMode='text'
          mode='underline'
          name='pincode'
          onChange={setCode}
          type='pinCode'
          value={code}
        />
        <button disabled={isButtonDisabled} type='submit'>
          {isLoading ? <Loader height='40px' /> : getTranslation('Submit')}
        </button>
      </form>

      <p className={styles.error}>{getTranslation(error)}</p>
    </div>
  )
}

export default DonorLoginForm
