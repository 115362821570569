import React, { useState } from 'react'

import { ASSET_PAGINATION_SIZE, VALID_ASSET_TYPES } from '@utils/constants'

import Asset from './Asset'

import styles from './styles.module.css'

const ChurchMedia = ({ data }) => {
  if (!data.fcp.assets) return null

  const [numAssetsToDisplay, setNumAssetsToDisplay] = useState(ASSET_PAGINATION_SIZE)
  const validAssets = data.fcp.assets.filter(({ contentType }) => VALID_ASSET_TYPES.some(type => contentType.includes(type)))

  return (
    <div className={styles['church-media']}>
      <div className={styles.assets}>
        {validAssets.slice(0, numAssetsToDisplay).map((asset, idx) => (
          <Asset asset={asset} key={idx} />
        ))}
      </div>

      {validAssets.length > numAssetsToDisplay && (
        <button onClick={() => setNumAssetsToDisplay(prev => prev + ASSET_PAGINATION_SIZE)}>View More</button>
      )}
    </div>
  )
}

export default ChurchMedia
