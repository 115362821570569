import React from 'react'

const ChevronDown = ({ title }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke='currentColor'
      fill='none'
      height='20'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='20'
    >
      <title>{title}</title> <polyline points='6 9 12 15 18 9' />
    </svg>
  )
}

export default ChevronDown
