import React from 'react'

import { formatDate } from '@utils/helpers'

import Accordion from '@components/Accordion'
import AccordionItem from '@components/Accordion/AccordionItem'
import RichTextContent from '@components/RichText'

const Reports = ({ reports }) => {
  if (!reports?.length) return null

  return (
    <Accordion>
      {reports.map((report, idx) => (
        <AccordionItem
          header={
            <div>
              <span>{formatDate(report.date)}</span>
              <small>{report.type}</small>
            </div>
          }
          key={`${report.date}-${idx}`}
        >
          <RichTextContent content={report.report} />
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default Reports
