import React from 'react'

import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps'
import { Tooltip } from 'react-tooltip'

import { getCountryCoordinates } from '@utils/map'
import countriesTopoJson from '@utils/map/world-countries.json' //https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json

import styles from './styles.module.css'

const Map = ({ targetCountry }) => {
  const zoomCenter = getCountryCoordinates(targetCountry)
  return (
    <div className={styles.map}>
      <ComposableMap projection='geoMercator'>
        <ZoomableGroup center={zoomCenter} zoom={6} minZoom={1.5} filterZoomEvent={e => e.type !== 'wheel'}>
          <Geographies geography={countriesTopoJson}>
            {({ geographies }) =>
              geographies.map(geo => {
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={geo.properties.name === targetCountry ? 'var(--primary-accent)' : '#e3e4e2'}
                    stroke='#fff'
                    style={{
                      default: {
                        outline: 'none'
                      },
                      hover: {
                        fill: geo.properties.name === targetCountry ? 'var(--primary-accent)' : '#d2d3d1',
                        outline: 'none'
                      }
                    }}
                    data-tooltip-id='map-tooltip'
                    data-tooltip-content={geo.properties.name}
                    data-tooltip-float
                    tabIndex={-1}
                  />
                )
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip id='map-tooltip' />
    </div>
  )
}

export default Map
