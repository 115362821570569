import React from 'react'

import { Input, Loader } from '@cvg/react-components'

import styles from './styles.module.css'

const DonorAddForm = ({ isSubmitting, state, callbacks }) => {
  const disabled = isSubmitting || !state.donorName || !state.fcpId

  return (
    <form className={styles.form}>
      <Input
        label='Donor Name'
        name='donorName'
        onChange={e => callbacks.setDonor(e.target.name, e.target.value)}
        type='text'
        value={state.donorName}
      />
      <Input
        label='FCP ID'
        name='fcpId'
        onChange={e => callbacks.setDonor(e.target.name, e.target.value)}
        type='text'
        value={state.fcpId}
      />
      <button type='submit' disabled={disabled} onClick={callbacks.submitDonor}>
        {isSubmitting ? <Loader height='40px' /> : 'Submit'}
      </button>
    </form>
  )
}

export default DonorAddForm
