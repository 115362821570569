import React from 'react'

const Children = ({ title }) => {
  return (
    <svg
      viewBox='12.480484962463379 9.89844036102295 56.04450225830078 60.20035934448242'
      fill='#005AB2'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path d='M36.7444 26.2857C41.2619 26.2857 44.9381 22.6095 44.9381 18.0921C44.9381 13.5746 41.2619 9.89844 36.7444 9.89844C32.227 9.89844 28.5508 13.5746 28.5508 18.0921C28.5508 22.6095 32.227 26.2857 36.7444 26.2857ZM36.7444 11.2603C40.5127 11.2603 43.5762 14.3238 43.5762 18.0921C43.5762 21.8603 40.5127 24.9238 36.7444 24.9238C32.9762 24.9238 29.9127 21.8603 29.9127 18.0921C29.9127 14.3238 32.9762 11.2603 36.7444 11.2603Z'></path>
      <path d='M50.4455 41.6792C54.8455 41.6792 58.4106 38.1141 58.4106 33.7141C58.4106 29.3141 54.8455 25.749 50.4455 25.749C46.0455 25.749 42.4805 29.3141 42.4805 33.7141C42.4805 38.1141 46.0455 41.6792 50.4455 41.6792ZM50.4455 27.1109C54.0868 27.1109 57.0487 30.0728 57.0487 33.7141C57.0487 37.3554 54.0868 40.3173 50.4455 40.3173C46.8043 40.3173 43.8424 37.3554 43.8424 33.7141C43.8424 30.0728 46.8043 27.1109 50.4455 27.1109Z'></path>
      <path d='M67.1665 38.2791C66.4649 37.5267 65.4681 37.095 64.43 37.095C63.4713 37.095 62.5633 37.4505 61.8903 38.0855L59.0046 40.6378C53.1601 45.9267 45.6935 44.2505 42.4681 41.4474L39.1316 38.0442C38.4427 37.3966 37.5347 37.041 36.576 37.041C35.5347 37.041 34.5347 37.4728 33.8363 38.2251C33.157 38.9553 32.8014 39.9109 32.8363 40.9109C32.8713 41.9109 33.2935 42.8378 34.0459 43.5394L41.6617 50.0347L42.1506 54.4505C42.1887 54.7966 42.484 55.0537 42.8268 55.0537C42.8522 55.0537 42.8776 55.0537 42.903 55.0505C43.2776 55.0093 43.5443 54.6728 43.503 54.2982L42.9855 49.6156C42.9665 49.4442 42.8808 49.2855 42.7506 49.1743L34.9538 42.5236C34.4903 42.0918 34.2205 41.5013 34.1982 40.8632C34.176 40.2251 34.4014 39.6188 34.8332 39.1521C35.2776 38.6759 35.9125 38.3997 36.5792 38.3997C37.1919 38.3997 37.7665 38.6251 38.1824 39.0156L41.5157 42.4188C41.5157 42.4188 41.5411 42.4442 41.5538 42.4537C43.6141 44.2569 46.7093 45.3807 49.8363 45.4601C52.4459 45.5204 56.3094 44.9172 59.9157 41.6537L62.8109 39.0918C63.2459 38.6823 63.8205 38.4569 64.4332 38.4569C65.1062 38.4569 65.722 38.7236 66.1728 39.2093C67.0649 40.1648 67.0173 41.6664 66.0713 42.5648L57.8776 49.2664C57.7379 49.3807 57.649 49.5458 57.63 49.7267L57.4871 50.714C57.4522 51.0886 57.7252 51.422 58.0998 51.4569C58.4713 51.495 58.8078 51.2188 58.8427 50.8442L58.957 50.1394L66.9506 43.6029C66.9506 43.6029 66.9728 43.5839 66.9855 43.5743C68.4935 42.1648 68.5728 39.7902 67.1665 38.2823V38.2791Z'></path>
      <path d='M68.4878 60.2321C68.3703 59.8734 67.9862 59.6797 67.6275 59.7972L40.6148 68.7019L13.3767 59.575C13.0211 59.4543 12.6338 59.648 12.5164 60.0035C12.3957 60.3591 12.5894 60.7464 12.9449 60.8638L40.3989 70.0638C40.4687 70.0861 40.5418 70.0988 40.6148 70.0988C40.6878 70.0988 40.7576 70.0861 40.8275 70.0638L68.056 61.0892C68.4116 60.9718 68.6084 60.5876 68.491 60.2289L68.4878 60.2321Z'></path>
      <path d='M16.5128 56.7967C16.8398 56.9808 17.2556 56.8665 17.4398 56.5395L18.5985 54.4983C20.6493 54.8602 28.9128 56.3935 32.9382 58.311C37.4461 60.457 39.8715 63.7014 39.8969 63.7332C40.0302 63.9141 40.2366 64.0126 40.4461 64.0126C40.4652 64.0126 40.4842 64.0062 40.5033 64.003C40.5223 64.003 40.5413 64.0126 40.5604 64.0126C40.7699 64.0126 40.9763 63.9173 41.1096 63.7332C41.135 63.7014 43.5413 60.4665 48.0652 58.311C52.1128 56.384 60.4525 54.8443 62.4398 54.4919L63.8302 56.5808C64.0398 56.8919 64.462 56.9776 64.7731 56.7713C65.0874 56.5618 65.1699 56.1395 64.9636 55.8284L63.3286 53.3681C63.1794 53.1459 62.9128 53.0284 62.6525 53.0729C62.2302 53.1427 52.2461 54.8094 47.481 57.0792C43.8048 58.8284 41.462 61.2157 40.5033 62.3205C39.5445 61.2157 37.2017 58.8284 33.5255 57.0792C28.7604 54.8094 18.7763 53.1427 18.354 53.0729C18.0715 53.0253 17.7921 53.1618 17.6525 53.4094L16.2588 55.8665C16.0747 56.1935 16.189 56.6094 16.5159 56.7935L16.5128 56.7967Z'></path>
      <path d='M19.8552 49.1302C19.8234 49.1302 19.7917 49.1302 19.7567 49.1238C19.3853 49.0699 19.125 48.727 19.179 48.3524C19.1948 48.2349 20.8742 36.5461 21.4805 32.4508C22.1218 28.1302 23.7536 26.6096 29.0805 25.3778C29.3155 25.3238 29.5599 25.3969 29.725 25.5715C29.7504 25.5969 32.4901 28.381 36.744 28.381C37.1186 28.381 37.4234 28.6857 37.4234 29.0603C37.4234 29.4349 37.1186 29.7397 36.744 29.7397C32.6837 29.7397 29.9059 27.5715 29.0329 26.7842C24.5472 27.8635 23.3694 29.0127 22.8298 32.6476C22.2234 36.7397 20.5472 48.4254 20.5282 48.5429C20.4805 48.8826 20.1885 49.127 19.8552 49.127V49.1302Z'></path>
      <path d='M36.7448 29.7394C36.3702 29.7394 36.0654 29.4346 36.0654 29.06C36.0654 28.6854 36.3702 28.3807 36.7448 28.3807C37.9289 28.3807 39.1035 28.168 40.2369 27.7457C40.5892 27.6156 40.9829 27.7934 41.1131 28.1457C41.2432 28.4981 41.0654 28.8886 40.713 29.0219C39.4273 29.5013 38.094 29.7426 36.7448 29.7426V29.7394Z'></path>
    </svg>
  )
}

export default Children
