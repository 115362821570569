import React from 'react'

import Heading from './Heading'
import Location from './Location'

import Impact from './Impact'
import FocusAreas from './FocusAreas'
import LearnMore from './LearnMore'

import styles from './styles.module.css'

const FcpDetails = ({ data }) => {
  return (
    <div className={styles['fcp-details']}>
      <div>
        <Heading supporterName={data.supporter.name} fcpName={data.fcp.name} />
        <Location city={data.fcp.city} country={data.fcp.country} />
      </div>

      <div>
        <Impact data={data} />
        <FocusAreas focusAreas={data.fcp.focusAreas} />
        <LearnMore fcp={data.fcp} />
      </div>
    </div>
  )
}

export default FcpDetails
