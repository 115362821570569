import React from 'react'

import useHomeData from './useHomeData'

import Page from '@components/Page'
import DonorLoginForm from '@components/DonorLoginForm'

const Home = () => {
  const { error, handleCodeSubmit, isLoading } = useHomeData()

  return (
    <Page>
      <Page.Header />
      <Page.Main>
        <Page.Section heading='Enter Your Unique Code'>
          <DonorLoginForm error={error} isLoading={isLoading} onSubmit={handleCodeSubmit} />
        </Page.Section>
      </Page.Main>
    </Page>
  )
}

export default Home
