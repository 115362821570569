import React from 'react'

import ChildrenIcon from '@components/Icons/Children'

import styles from './styles.module.css'

const Impact = ({ data }) => {
  const { participantCount } = data?.fcp || {}

  if (!participantCount) return null

  return (
    <div className={styles.impact}>
      <div className={styles.stat}>
        <ChildrenIcon />
        <div>
          <b>{data.fcp.participantCount}</b>
          <small>children</small>
          <hr />
        </div>
      </div>
      <p>impacted through your partnership</p>
    </div>
  )
}

export default Impact
