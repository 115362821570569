import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ERROR_MESSAGES } from '@utils/constants'
import { getDonorExists } from '@services/donors'

const useHomeData = () => {
  const navigate = useNavigate()

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleCodeSubmit = async code => {
    try {
      setError(null)
      setIsLoading(true)
      const uppercaseCode = code.toUpperCase()

      const { exists } = await getDonorExists(uppercaseCode)

      if (exists) {
        navigate(`/${uppercaseCode}`)
      } else {
        setError(ERROR_MESSAGES.invalidCode)
      }
    } catch (error) {
      setError(ERROR_MESSAGES.generalError)
    } finally {
      setIsLoading(false)
    }
  }

  return { error, handleCodeSubmit, isLoading }
}

export default useHomeData
