import React from 'react'

import useDashboardData from './useDashboardData'

import Page from '@components/Page'
import FcpDetails from './FcpDetails'
import ChurchMedia from './ChurchMedia'
import Roadmap from './Roadmap'
import ProgressUpdates from './ProgressUpdates'
import Reports from './Reports'
import CommunityInfo from './CommunityInfo'
import CommunityContext from './CommunityContext'

const Dashboard = () => {
  const { data, error, isLoading } = useDashboardData()

  return (
    <Page>
      <Page.Header name={data?.supporter.name} />
      <Page.AsyncContent isLoading={isLoading} isError={!!error}>
        <Page.Main>
          <Page.Section>
            <FcpDetails data={data} />
          </Page.Section>

          <Page.Section heading='From The Church'>
            <ChurchMedia data={data} />
          </Page.Section>

          <Page.Section heading='Roadmap'>
            <Roadmap roadmap={data?.fcp.roadmap} />
          </Page.Section>

          <Page.Section heading='Progress Updates'>
            <ProgressUpdates updates={data?.fcp.progressUpdates} />
          </Page.Section>

          <Page.Section heading='Reports'>
            <Reports reports={data?.fcp.reports} />
          </Page.Section>

          <Page.Section heading='Community Info'>
            <CommunityInfo info={data?.fcp.communityInfo} />
          </Page.Section>

          <Page.Section heading='Community Situation'>
            <CommunityContext context={data?.fcp.communityContext} />
          </Page.Section>
        </Page.Main>
      </Page.AsyncContent>
    </Page>
  )
}

export default Dashboard
