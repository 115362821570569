import React from 'react'

import { Accordion as ReactAccordion } from '@szhsin/react-accordion'

import styles from './styles.module.css'

const Accordion = ({ children }) => {
  return (
    <ReactAccordion className={styles.accordion} transition transitionTimeout={250} allowMultiple>
      {children}
    </ReactAccordion>
  )
}

export default Accordion
