import React from 'react'

import DonorCard from './DonorCard'

import styles from './styles.module.css'

const CurrentDonors = ({ donors }) => {
  return (
    <ul className={styles.donors}>
      {donors
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(donor => (
          <DonorCard key={donor.donorId} donor={donor} />
        ))}
    </ul>
  )
}

export default CurrentDonors
