import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import auth from '@services/auth'

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const logout = async () => {
      await auth.logout()

      navigate('/login')
    }

    logout()
  }, [])

  return null
}

export default Logout
