import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import { OtpLogin } from '@cvg/react-otp-auth'
import { useLanguageContext } from '@cvg/react-hooks'

import auth from '@services/auth'
import { LOGIN_STEPS } from '@utils/constants'

import Page from '@components/Page'

import styles from './styles.module.css'

const Login = () => {
  const [currentStep, setCurrentStep] = useState(LOGIN_STEPS.enterEmail)

  const navigate = useNavigate()
  const { languageId } = useLanguageContext()

  if (auth.getUser()) return <Navigate to='/' />

  return (
    <Page className={styles.login}>
      <Page.Header />
      <Page.Main>
        <Page.Section className={styles.section} heading={LOGIN_STEPS[currentStep]}>
          <OtpLogin
            auth={auth}
            languageId={languageId}
            onStepChange={step => setCurrentStep(step)}
            redirectCallback={() => navigate('/admin')}
            theme={{ color: '#005eb8' }}
          />
        </Page.Section>
      </Page.Main>
    </Page>
  )
}

export default Login
