import React from 'react'

import { AccordionItem as ReactAccordionItem } from '@szhsin/react-accordion'

import ChevronIcon from '@components/Icons/ChevronDown'

import styles from '../styles.module.css'

const AccordionItem = ({ children, header }) => {
  return (
    <ReactAccordionItem
      header={
        <>
          {header}
          <ChevronIcon />
        </>
      }
      className={styles['accordion-item']}
      buttonProps={{
        className: ({ isEnter }) => `${styles['accordion-btn']} ${isEnter && styles['accordion-btn-expanded']}`
      }}
      panelProps={{ className: styles['accordion-panel'] }}
      contentProps={{ className: styles['accordion-content'] }}
    >
      {children}
    </ReactAccordionItem>
  )
}

export default AccordionItem
