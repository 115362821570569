import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { useNavigate } from 'react-router-dom'

import auth from '@services/auth'

import Admin from '@pages/Admin'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import Dashboard from '@pages/Dashboard'
import Home from '@pages/Home'
import Login from '@pages/Login'
import Logout from '@pages/Logout'

import { useLanguageContext } from '@cvg/react-hooks'

const AppRoutes = () => {
  useLanguageContext()

  const navigate = useNavigate()

  useEffect(() => {
    auth.setSessionExpirationCallback(() => {
      navigate('/logout')
    })
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='logout' element={<Logout />} />
      <Route path='admin' element={<AuthenticatedRoutes />}>
        <Route index element={<Admin />} />
      </Route>
      <Route path=':id' element={<Dashboard />} />
    </Routes>
  )
}

export default AppRoutes
