import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Section = ({ className = '', children, heading, ...rest }) => {
  const { getTranslation } = useLanguageContext()
  return (
    <section className={`${styles.section} ${className}`.trim()}>
      {heading && <h2>{getTranslation(heading)}</h2>}
      <div className={styles['section-content']} {...rest}>
        {children}
      </div>
    </section>
  )
}

export default Section
