import React from 'react'
import { CustomContextProvider } from '@cvg/react-hooks'

import AsyncContent from './AsyncContent'
import Header from './Header'
import Main from './Main'
import Section from './Section'

import styles from './styles.module.css'

const Page = ({ children, context, ...rest }) => {
  if (!context)
    return (
      <div className={styles.page} {...rest}>
        {children}
      </div>
    )

  return (
    <CustomContextProvider context={context}>
      <div className={styles.page} {...rest}>
        {children}
      </div>
    </CustomContextProvider>
  )
}

Page.AsyncContent = AsyncContent
Page.Header = Header
Page.Main = Main
Page.Section = Section

export default Page
