import React from 'react'

import styles from './styles.module.css'

const Heading = ({ supporterName, fcpName }) => {
  return (
    <h1 className={styles.heading}>
      <div className={styles['supporter-name']}>
        {supporterName && <b>{supporterName}</b>}
        <span>partnering with</span>
      </div>
      <div className={styles['fcp-name']}>{fcpName}</div>
    </h1>
  )
}

export default Heading
