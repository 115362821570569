import React, { memo, useRef } from 'react'
import { VideoComponents } from '@cvg/react-components'

import Dialog from '@components/Dialog'
import Description from '../Description'
import PlayIcon from '@components/Icons/Play'

import styles from './styles.module.css'

const { VideoPlayer, VIDEO_PROVIDER } = VideoComponents

const Video = ({ asset }) => {
  const videoRef = useRef()

  return (
    <Dialog onClose={() => videoRef.current.pause()} onOpen={() => videoRef.current.play()}>
      <Dialog.Open className={styles['dialog-open']}>
        <video preload='metadata'>
          <source src={`${asset.url}#t=0.001`} type={asset.contentType} />
        </video>
        <Description description={asset.description} preview={true} />
        <PlayIcon />
      </Dialog.Open>

      <Dialog.Container>
        <Dialog.Header />
        <Dialog.Content className={styles['dialog-content']}>
          <VideoPlayer playsInline={true} videoRef={videoRef} videoType={VIDEO_PROVIDER.url} videoUrl={asset.url} />
          <Description description={asset.description} />
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default memo(Video)
