import React from 'react'

import LearnMoreDialog from './LearnMoreDialog'

import styles from './styles.module.css'

const LearnMore = ({ fcp }) => {
  return (
    <div className={styles['learn-more']}>
      <LearnMoreDialog data={fcp} label='Learn about your Partner Church' show={!!fcp?.bio} />
      <LearnMoreDialog data={fcp.pastor} label='Learn about the Pastor' show={!!fcp?.pastor} />
      <LearnMoreDialog data={fcp.projectDirector} label='Learn about the Project Director' show={!!fcp?.projectDirector} />
    </div>
  )
}

export default LearnMore
