import React from 'react'

import useAdminData from './useAdminData'

import Page from '@components/Page'
import DonorAddForm from '@components/DonorAddForm'
import CurrentDonors from '@components/CurrentDonors'

const Admin = () => {
  const { callbacks, donors, isSubmitting, state } = useAdminData()

  return (
    <Page>
      <Page.Header />
      <Page.AsyncContent isError={!!donors.error} isLoading={donors.isLoading}>
        <Page.Main>
          <Page.Section heading='Add Donor'>
            <DonorAddForm callbacks={callbacks} isSubmitting={isSubmitting} state={state} />
          </Page.Section>
          <Page.Section heading='Current Donors'>
            <CurrentDonors donors={donors.data} />
          </Page.Section>
        </Page.Main>
      </Page.AsyncContent>
    </Page>
  )
}

export default Admin
