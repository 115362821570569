import React from 'react'

const ArrowRight = ({ title }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='16px' width='16px' viewBox='0 0 24 24'>
      <title>{title}</title>
      <path d='M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z' />
    </svg>
  )
}

export default ArrowRight
