import React from 'react'

import Image from '../Image'
import Video from '../Video'

import styles from './styles.module.css'

const Asset = ({ asset }) => {
  return (
    <div className={styles.asset}>
      {asset.contentType.startsWith('image/') ? <Image asset={asset} /> : <Video asset={asset} />}
    </div>
  )
}

export default Asset
