import localize from '@cvg/localization-util'
import logger from '@utils/logger'

import en from './en.json'

const locales = {
  en: {
    name: 'English',
    values: en
  }
}

const options = {
  logErrorCallback: message => logger.error(message, { locales })
}

export default localize(locales, options)
