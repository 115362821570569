import React from 'react'

import { formatDate } from '@utils/helpers'

import Accordion from '@components/Accordion'
import AccordionItem from '@components/Accordion/AccordionItem'
import RichTextContent from '@components/RichText'

const ProgressUpdates = ({ updates = [] }) => {
  if (!updates?.length) return null

  return (
    <Accordion>
      {updates.map((update, idx) => (
        <AccordionItem
          header={
            <div>
              <span>{formatDate(update.date)}</span>
              <small>from the {update.from}</small>
            </div>
          }
          key={`${update.date}-${idx}`}
        >
          <RichTextContent content={update.update} />
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default ProgressUpdates
