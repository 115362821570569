import React, { memo } from 'react'

import Dialog from '@components/Dialog'
import Description from '../Description'

import styles from './styles.module.css'

const Image = ({ asset }) => {
  return (
    <Dialog>
      <Dialog.Open>
        <img src={asset.url} alt={asset.description} />
        <Description description={asset.description} preview={true} />
      </Dialog.Open>

      <Dialog.Container>
        <Dialog.Header />
        <Dialog.Content className={styles['dialog-content']}>
          <img src={asset.url} alt={asset.description} />
          <Description description={asset.description} />
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default memo(Image)
