import React from 'react'

import GlobeIcon from '@components/Icons/Globe'
import Map from './Map'

import styles from './styles.module.css'

const Location = ({ city, country }) => {
  return (
    <div className={styles.location}>
      <div className={styles['city-country']}>
        <GlobeIcon />
        <span>{`${city}, ${country}`}</span>
      </div>
      <Map targetCountry={country} />
    </div>
  )
}

export default Location
