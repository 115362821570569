import React from 'react'

import { Dialog } from '@cvg/react-components'
import CloseIcon from '@components/Icons/Close'

import styles from './styles.module.css'

const Header = () => {
  return (
    <header className={styles.header}>
      <Dialog.Close>
        <CloseIcon title='Close' />
      </Dialog.Close>
    </header>
  )
}

export default Header
