import React from 'react'

import { IMAGE_ORIENTATION } from '@utils/constants'

import Dialog from '@components/Dialog'
import ArrowIcon from '@components/Icons/ArrowRight'
import RichTextContent from '@components/RichText'

import styles from './styles.module.css'

const LearnMoreDialog = ({ data, label, show }) => {
  if (!show) return null

  const imageOrientation = image => {
    return image.width > image.height ? IMAGE_ORIENTATION.landscape : IMAGE_ORIENTATION.portrait
  }

  return (
    <Dialog>
      <Dialog.Open className={styles['dialog-opener']}>
        <span>{label}</span>
        <ArrowIcon />
      </Dialog.Open>

      <Dialog.Container>
        <Dialog.Header />
        <Dialog.Content className={styles['dialog-content']}>
          {data.photo?.url && (
            <div data-image={imageOrientation(data.photo.details.image)}>
              <img src={data.photo.url} alt={data?.name} />
            </div>
          )}
          <div data-bio>
            <h2>{data.name}</h2>
            <RichTextContent content={data.bio} />
          </div>
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default LearnMoreDialog
