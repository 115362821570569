import React, { useMemo } from 'react'

import Accordion from '@components/Accordion'
import AccordionItem from '@components/Accordion/AccordionItem'
import Milestone from './Milestone'

import styles from './styles.module.css'

const Roadmap = ({ roadmap = {} }) => {
  const { milestones = [], vision } = roadmap

  const milestoneYears = useMemo(
    () => [...new Set(milestones.map(ms => new Date(ms.target_date).getFullYear()))],
    [milestones]
  )

  if (!vision || !milestones?.length) return null

  return (
    <div className={styles.roadmap}>
      <p>{vision}</p>

      <Accordion>
        {milestoneYears.map((year, idx) => (
          <AccordionItem header={year} key={`milestone-${year}-${idx}`}>
            {milestones
              .filter(ms => new Date(ms.target_date).getFullYear() === year)
              .map((ms, idx) => (
                <Milestone data={ms} key={`milestone-${ms.goal}-${idx}`} />
              ))}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default Roadmap
