import React from 'react'

import Logo from '/logo.png'
import styles from './styles.module.css'

const Header = () => {
  return (
    <header className={styles.header}>
      <div>
        <img alt='Compassion International Logo' src={Logo} height='46' />
      </div>
    </header>
  )
}

export default Header
