import React from 'react'

import styles from './styles.module.css'

const Description = ({ description, preview = false }) => {
  return (
    <div className={styles.description}>
      <p data-preview={preview}>{description}</p>
    </div>
  )
}

export default Description
