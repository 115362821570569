import { useReducer } from 'react'
import { mutate } from 'swr'

import { useFetch, useLanguageContext, useSubmit } from '@cvg/react-hooks'
import { showError, showSuccess } from '@cvg/react-components'

import { addDonor, getDonors } from '@services/admin'
import { CACHE_KEYS } from '@utils/swr'

const useAdminData = () => {
  const { getTranslation } = useLanguageContext()

  const initialState = {
    donorName: '',
    fcpId: ''
  }

  const [state, dispatch] = useReducer((previousState, action) => {
    const { changes } = action

    switch (action.type) {
      case 'SET_DONOR':
        return { ...previousState, ...changes }

      case 'RESET':
        return initialState
    }
  }, initialState)

  const donors = useFetch(getDonors, { cacheKey: CACHE_KEYS.donors })

  const donor = useSubmit(addDonor, {
    onError: error => {
      dispatch({ type: 'RESET' })
      showError(error.message, { isPersistent: true })
    },
    onSuccess: newDonor => {
      dispatch({ type: 'RESET' })
      mutate(CACHE_KEYS.donors, [...donors.data, { ...newDonor }])
      showSuccess(getTranslation(`A new donor page has been created!`))
    }
  })

  const callbacks = {
    reset: () => dispatch({ type: 'RESET' }),
    setDonor: (key, value) => dispatch({ type: 'SET_DONOR', changes: { [key]: value } }),
    submitDonor: () => donor.submit(state)
  }

  return {
    callbacks,
    donors,
    isSubmitting: donor.isSubmitting,
    state
  }
}

export default useAdminData
