import { DATA_FORMATTER_KEYS } from '@utils/constants'

export const formatDate = timestamp => {
  const date = new Date(`${timestamp}T00:00:00`)

  return date.toLocaleString('en-US', { dateStyle: 'short' })
}

export const formatData = (key, data) => {
  switch (key) {
    case DATA_FORMATTER_KEYS.income:
    case DATA_FORMATTER_KEYS.population:
      return isNaN(data) ? data : Number(data).toLocaleString()

    case DATA_FORMATTER_KEYS.airport:
      return `${Math.floor(data * 0.621371)} miles`

    case DATA_FORMATTER_KEYS.medical:
      return data.includes('minutes') ? data : `${data} minutes`

    case DATA_FORMATTER_KEYS.unemployment:
      return `${data}%`

    default:
      return data
  }
}
