import React from 'react'

const Close = ({ title }) => {
  return (
    <svg width='10' height='10' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <title>{title}</title>
      <path d='M0 21.3449L9.39696 12.0521L0 2.78525L2.78525 0L12.0521 9.44902L21.3189 0L24 2.68113L14.551 11.9479L24 21.2148L21.2148 24L11.9479 14.551L2.68113 24L0 21.3449Z' />
    </svg>
  )
}

export default Close
