import { useNavigate, useParams } from 'react-router-dom'

import { useFetch } from '@cvg/react-hooks'

import { getDonorById } from '@services/donors'

const useDashboardData = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, error, isLoading } = useFetch(() => getDonorById(id), {
    cacheKey: 'donor',
    swrOptions: { onError: () => navigate('/') }
  })

  return { data, error, isLoading }
}

export default useDashboardData
