export const ASSET_PAGINATION_SIZE = 6
export const SUPPORTER_CODE_LENGTH = 5

export const COMMUNITY_CONTEXT_LABELS = Object.freeze({
  economicContextAndNeeds: 'Economic',
  healthContextAndNeeds: 'Health',
  socialContextAndNeeds: 'Social',
  spiritualContextAndNeeds: 'Spiritual'
})

export const COMMUNITY_INFO_LABELS = Object.freeze({
  population: 'Population',
  unemploymentRate: 'Unemployment Rate',
  averageFamilyMonthlyIncomeLocalCurrency: 'Avg Family Income/Month (Local Currency)',
  primaryAdultOccupations: 'Primary Adult Occupations',
  closestMajorCity: 'Closest Major City',
  closestAirportKm: 'Distance to Nearest Airport',
  timeToNearestMedicalFacility: 'Time to Nearest Medical Facility',
  hungerMonths: 'Hunger Months',
  rainyMonths: 'Rainy Months',
  typicalHomeRoof: 'Typical Home Roof',
  typicalHomeWall: 'Typical Home Wall'
})

export const DATA_FORMATTER_KEYS = Object.freeze({
  airport: 'closestAirportKm',
  income: 'averageFamilyMonthlyIncomeLocalCurrency',
  medical: 'timeToNearestMedicalFacility',
  population: 'population',
  unemployment: 'unemploymentRate'
})

export const ERROR_MESSAGES = Object.freeze({
  generalError: 'GENERAL_ERROR',
  invalidCode: 'INVALID_CODE'
})

export const IMAGE_ORIENTATION = Object.freeze({
  landscape: 'landscape',
  portrait: 'portrait'
})

export const LOGIN_STEPS = Object.freeze({
  enterEmail: 'enterEmail',
  confirmCode: 'confirmCode'
})

export const VALID_ASSET_TYPES = ['image/', 'video/']
