import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LanguageContextProvider } from '@cvg/react-hooks'

import localize from '@utils/localization'

import AppRoutes from '@components/AppRoutes'

const App = () => {
  return (
    <LanguageContextProvider localize={localize} storage={localStorage}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </LanguageContextProvider>
  )
}

export default App
