import React from 'react'

const Play = () => {
  return (
    <svg width='62' height='67' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Play</title>
      <g filter='url(#a)'>
        <path
          d='M43.37 30.193 19.983 16.19a1.32 1.32 0 0 0-1.803.438c-.118.195-.18.417-.18.644v28.006c0 .227.062.45.18.644.117.196.285.357.487.467a1.322 1.322 0 0 0 1.316-.029L43.37 32.357a1.27 1.27 0 0 0 .461-.46 1.237 1.237 0 0 0 0-1.245 1.27 1.27 0 0 0-.461-.46Z'
          stroke='#fff'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          shapeRendering='crispEdges'
        />
      </g>
      <defs>
        <filter id='a' x='0' y='0' width='62' height='66.55' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='8' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_990_271330' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_990_271330' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default Play
