import React from 'react'

import styles from './styles.module.css'

const FocusAreas = ({ focusAreas }) => {
  if (!focusAreas?.length) return null

  return (
    <div className={styles['focus-areas']}>
      <p>Areas of focus</p>
      <ul>
        {focusAreas.map(item => (
          <li key={item}>{item.replaceAll(/Sponsorship/gi, 'Development')}</li>
        ))}
      </ul>
    </div>
  )
}

export default FocusAreas
