import React, { Fragment } from 'react'

import { COMMUNITY_CONTEXT_LABELS } from '@utils/constants'

import styles from './styles.module.css'

const CommunityContext = ({ context = {} }) => {
  return (
    <div className={styles['community-context']}>
      <dl>
        {Object.entries(COMMUNITY_CONTEXT_LABELS).map(([key, label], idx) => {
          if (context[key])
            return (
              <Fragment key={idx}>
                <dt>{label}:</dt>
                <dd>{context[key]}</dd>
              </Fragment>
            )
        })}
      </dl>
    </div>
  )
}

export default CommunityContext
