import React from 'react'

import { AsyncContent as CVGAsyncContent } from '@cvg/react-components'

import { useLanguageContext } from '@cvg/react-hooks'

const AsyncContent = ({ children, isError, isLoading }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <CVGAsyncContent
      errorFallback={getTranslation('Unexpected error occurred.')}
      isError={isError}
      isLoading={isLoading}
      loadingFallback={getTranslation('Loading data...')}
    >
      {children}
    </CVGAsyncContent>
  )
}

export default AsyncContent
