import React from 'react'

import { formatDate } from '@utils/helpers'

import styles from './styles.module.css'

const Milestone = ({ data }) => {
  return (
    <dl className={styles.milestone}>
      <dt>Goal:</dt>
      <dd>{data.goal}</dd>

      <dt>Target Date:</dt>
      <dd>{formatDate(data.target_date)}</dd>

      {!!data.impact.length && (
        <>
          <dt>Impacts:</dt>
          <dd>
            <ul>
              {data.impact.map(impact => (
                <li key={impact}>{impact}</li>
              ))}
            </ul>
          </dd>
        </>
      )}
    </dl>
  )
}

export default Milestone
